import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  IconButton,
  Link,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{ backgroundColor: "#103444", color: "white", paddingTop: "50px" }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={3} sm={3}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
              OCULOGYX.AI
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              CONTACT
            </Typography>
            <Link
              href="mailto:abc@gmail.com"
              color="inherit"
              sx={{ display: "flex", alignItems: "center" }}
            >
              info@oculogyx.ai
            </Link>
            {/* <Link href="tel:+123456789" color="inherit">
                            +123 456 789
                        </Link> */}
          </Grid>
          <Grid item xs={3} sm={3}>
            <Link
              href="/privacy-policy"
              color="inherit"
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
              }}
            >
              {`Privacy Policy`}
            </Link>
            <Link
              href="/terms-and-conditions"
              color="inherit"
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
              }}
            >
              {`Terms & Conditions`}
            </Link>
          </Grid>
          <Grid item xs={3} sm={3}>
            <IconButton color="inherit">
              <FacebookIcon />
            </IconButton>
            <IconButton color="inherit">
              <TwitterIcon />
            </IconButton>
            <IconButton color="inherit">
              <InstagramIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box textAlign="center" py={2}>
          <Typography variant="caption">
            Copyright © 2024 Oculogyx - Bringing AI to Eye Care | All right
            reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
