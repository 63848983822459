import React, { useState, useEffect } from "react";
import MasterLayout from "./MasterLayout";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import "../App.css";

import awsExports from "../aws-exports";
import { getSessionJwt } from "../util/utilities";
import GptLayout from "./GptLayout";
import { ENDPOINTS } from "../util/constants";

Amplify.configure(awsExports);

export default function EyeGptLayout({
  headerText,
  subheaderText,
  assistantName = "eyegpt",
}) {
  const getInitialMessages = () => {
    switch (assistantName) {
      case "triage":
        return [
          {
            text: "Hi there! How can I assist you today with your eye health? Could you start by describing any issues or symptoms you are experiencing with your eyes?",
            sender: "gpt",
          },
        ];
      case "previsitInterview":
        return [
          {
            text: "Hello, I am an online agent for the doctor's office. Please answer these intake questions about your demographic information, your reason for the visit, and other certain questions to allow us to streamline your visit.",
            sender: "gpt",
          },
        ];
      default:
        return [];
    }
  };

  const [messages, setMessages] = useState(getInitialMessages());

  const [input, setInput] = useState("");
  const [threadID, setThreadID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [pastChats, setPastChats] = useState([]);
  const [isLoadingPastChats, setIsLoadingPastChats] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [waitingAfterDone, setWaitingAfterDone] = useState(false);

  // Define custom prompts based on the assistant name
  const getCustomPrompts = () => {
    switch (assistantName) {
      case "triage":
      case "wavefrontOptimizedTreatmentPlan":
      case "previsitInterview":
        return []; // No prompts for these cases
      case "refractiveSurgery":
        return [
          "Am I a candidate for PRK?",
          "Am I a candidate for Lasik?",
          "Am I a candidate for ICL?",
          "Should I consider a Refractive Lens Exchange?",
        ];
      case "ophthalmicImageStudyGuide":
        return [
          "Give me 20 multiple choice questions in Optics.",
          "Present an image and then test my knowledge.",
          "Test me on my patient interview technique.",
        ];
      case "researchAssistant":
        return [
          "Please summarize this paper.",
          "Please outline a clinical trial.",
          "Please calculate the sample size in this protocol.",
        ];
      default:
        return [
          "Tell me about an ocular condition",
          "Give me insights on a case description",
          "What is the best medication for a condition?",
          "How do I do a 'special test'",
          "What are some tips for a 'surgical procedure'",
          "Tell me the inheritance pattern for a certain condition",
        ];
    }
  };

  const customPrompts = getCustomPrompts();

  const sendMessage = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    const trimmedInput = input.trim();
    setInput("");

    if (trimmedInput) {
      const userMessage = {
        text: trimmedInput,
        sender: "user",
      };

      try {
        let eyeGptThreadID = threadID;

        setMessages((prevMessages) => [...prevMessages, userMessage]);

        const jwtToken = await getSessionJwt();

        if (!eyeGptThreadID) {
          const response = await fetch(
            `${ENDPOINTS.LAMBDA_ENDPOINT}/generate-thread-id`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwtToken}`,
              },
              body: JSON.stringify({ input: trimmedInput }),
            }
          );
          const data = await response.json();
          if (!data.results || !data.results.threadID) {
            console.error("Error generating chat id. Message:", data.message);
            alert(
              "It looks like our system is down for maintenance. Please try again later!"
            );
            setIsLoading(false);
            return;
          }
          setThreadID(data.results.threadID);
          eyeGptThreadID = data.results.threadID;
        }

        const payload = {
          threadID: eyeGptThreadID,
          assistantName: assistantName,
          input: trimmedInput,
        };

        const response = await fetch(
          `${ENDPOINTS.LAMBDA_ENDPOINT}/generate-text-eyegpt-assistant`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await response.json();

        if (!data.results || !data.results.response) {
          console.error("Error sending message. Message:", data.message);
          alert(
            "It looks like our system is down for maintenance. Please try again later!"
          );
          setIsLoading(false);
          return;
        }

        // Extract the last gpt message from the response
        if (data && data.results.response) {
          const lastGptMessage = data.results.response;
          if (lastGptMessage) {
            const apiMessage = {
              text: lastGptMessage,
              sender: "gpt",
            };
            setMessages((prevMessages) => [...prevMessages, apiMessage]);
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error sending message:", error);
        setIsLoading(false);
      }
    }
  };

  const sendMessageStream = async ({ event, text, files }) => {
    event.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    const trimmedInput = text.trim();
    setInput("");

    if (trimmedInput) {
      const userMessage = {
        text: trimmedInput,
        sender: "user",
      };
      const messagesToAdd = [userMessage];

      try {
        let eyeGptThreadID = threadID;

        const payload = new FormData();

        payload.append("input", trimmedInput);
        payload.append("assistantName", assistantName);

        const uniqueFiles = [];
        const fileNames = new Set();

        files.forEach((file) => {
          if (!fileNames.has(file.name)) {
            fileNames.add(file.name);
            uniqueFiles.push(file);
          }
        });

        uniqueFiles.forEach((file) => {
          payload.append("files", file);
          messagesToAdd.push({
            text: "[FILE ATTACHED]",
            sender: "user",
          });
        });

        setMessages((prevMessages) => [...prevMessages, ...messagesToAdd]);

        const jwtToken = await getSessionJwt();

        if (!eyeGptThreadID) {
          const response = await fetch(
            `${ENDPOINTS.LAMBDA_ENDPOINT}/generate-thread-id`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwtToken}`,
              },
              body: JSON.stringify({ input: trimmedInput }),
            }
          );
          const data = await response.json();
          if (!data.results || !data.results.threadID) {
            console.error("Error generating chat id. Message:", data.message);
            alert(
              "It looks like our system is down for maintenance. Please try again later!"
            );
            setIsLoading(false);
            return;
          }
          setThreadID(data.results.threadID);
          eyeGptThreadID = data.results.threadID;
        }
        payload.append("threadID", eyeGptThreadID);

        const response = await fetch(
          `${ENDPOINTS.LAMBDA_ENDPOINT}/generate-text-eyegpt-assistant-stream`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
            body: payload,
          }
        );

        // Using ReadableStream to process the streamed response
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          // Decode the stream chunk to text
          const chunk = decoder.decode(value, { stream: true });
          if (!isStreaming) {
            setIsStreaming(true);
          }

          // Update the messages in the UI as the stream progresses
          setMessages((prevMessages) => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            if (lastMessage?.sender === "gpt") {
              return [
                ...prevMessages.slice(0, prevMessages.length - 1),
                { ...lastMessage, text: lastMessage.text + chunk },
              ];
            } else {
              return [...prevMessages, { text: chunk, sender: "gpt" }];
            }
          });
        }

        setIsLoading(false);
        setIsStreaming(false);
      } catch (error) {
        console.error("Error sending message:", error);
        setIsLoading(false);
        setIsStreaming(false);
      }
    }
  };

  const getThreads = async (e) => {
    e.preventDefault();
    try {
      if (isLoadingPastChats) return;
      setIsLoadingPastChats(true);
      const jwtToken = await getSessionJwt();
      if (!jwtToken) {
        setIsLoadingPastChats(false);
        return;
      }
      const response = await fetch(`${ENDPOINTS.LAMBDA_ENDPOINT}/get-threads`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      const data = await response.json();
      if (data?.results?.success) {
        setPastChats(data.results.chats);
      }
    } catch (error) {
      console.error("Error getting chats. Message:", error);
    }
    setIsLoadingPastChats(false);
  };

  const getThread = async (e, threadID) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!threadID) {
        setIsLoading(false);
        return;
      }
      const jwtToken = await getSessionJwt();
      if (!jwtToken) {
        setIsLoading(false);
        return;
      }
      setMessages([]);
      const response = await fetch(
        `${ENDPOINTS.LAMBDA_ENDPOINT}/get-thread?threadID=${threadID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      const data = await response.json();
      if (data?.results?.success) {
        setMessages(data.results.chats);
        setThreadID(threadID);
      }
    } catch (error) {
      console.error("Error getting this chat. Message:", error);
    }
    setIsLoading(false);
  };

  return (
    <MasterLayout>
      <GptLayout
        headerText={headerText ?? "OPHTHGPT"}
        subheaderText={subheaderText}
        getChat={getThread}
        getChats={getThreads}
        sendMessage={sendMessageStream}
        isLoading={isLoading && !isStreaming}
        setIsLoading={setIsLoading}
        input={input}
        setInput={setInput}
        responseText={responseText}
        setResponseText={setResponseText}
        messages={messages}
        setMessages={setMessages}
        waitingAfterDone={waitingAfterDone}
        setWaitingAfterDone={setWaitingAfterDone}
        isLoadingPastChats={isLoadingPastChats}
        pastChats={pastChats}
        prompts={
          assistantName === "triage" ||
          assistantName === "wavefrontOptimizedTreatmentPlan"
            ? null
            : customPrompts
        }
      />
    </MasterLayout>
  );
}
