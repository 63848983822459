import MasterLayout from "./MasterLayout";
import React, { useState } from "react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import "../App.css";

import awsExports from "../aws-exports";
import { getSessionJwt } from "../util/utilities";
import GptLayout from "./GptLayout";
import { ENDPOINTS } from "../util/constants";

Amplify.configure(awsExports);

export default function OphthGptLayout() {
  const [messages, setMessages] = useState([
    // {
    //     text: 'test', sender: 'user'
    // },
    // {
    //     text: 'Hi, I\'m OphthGpt. How can I help you?', sender: 'gpt'
    // }
  ]);
  const [input, setInput] = useState("");
  const [chatID, setChatID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pastChats, setPastChats] = useState([]);
  const [isLoadingPastChats, setIsLoadingPastChats] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [waitingAfterDone, setWaitingAfterDone] = useState(false);

  console.log("input", input);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    const trimmedInput = input.trim();
    setInput("");

    if (trimmedInput) {
      const userMessage = {
        text: trimmedInput,
        sender: "user",
      };

      console.log("userMessage", userMessage);

      try {
        let quivrChatID = chatID;

        setMessages((prevMessages) => [...prevMessages, userMessage]);

        const jwtToken = await getSessionJwt();

        if (!quivrChatID) {
          const response = await fetch(
            `${ENDPOINTS.LAMBDA_ENDPOINT}/generate-chat-id`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwtToken}`,
              },
              body: JSON.stringify({ input: trimmedInput }),
            }
          );
          const data = await response.json();
          if (!data.results || !data.results.chatID) {
            console.error("Error generating chat id. Message:", data.message);
            alert(
              "It looks like our system is down for maintenance. Please try again later!"
            );
            setIsLoading(false);
            return;
          }
          setChatID(data.results.chatID);
          quivrChatID = data.results.chatID;
        }

        const payload = {
          chatID: quivrChatID,
          input: trimmedInput,
        };

        let response;

        try {
          response = await fetch(
            `${ENDPOINTS.EC2_ENDPOINT}/generate-text-stream`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwtToken}`,
              },
              body: JSON.stringify(payload),
            }
          );
        } catch {
          response = await fetch(
            `${ENDPOINTS.LAMBDA_ENDPOINT}/generate-text-stream`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwtToken}`,
              },
              body: JSON.stringify(payload),
            }
          );
        }

        if (!response || !response.ok) {
          alert(
            "It looks like our system is down for maintenance. Please try again later!"
          );
          console.log(
            "It looks like our system is down for maintenance. Please try again later!"
          );
          setIsLoading(false);
          return;
        }

        // Get the reader from the response stream
        const reader = response.body.getReader();

        // Text decoder to decode the stream
        const decoder = new TextDecoder("utf-8");

        // Read through the stream
        const read = ({ done, value }) => {
          if (done) {
            console.log("Stream complete");
            setWaitingAfterDone(true);
            return;
          }

          // Decode the stream chunk to a string
          const chunk = decoder.decode(value, { stream: true });
          // Update the state with the new data
          setResponseText((prevText) => prevText + chunk);

          // Read the next chunk
          reader.read().then(read);
        };

        // Start reading the stream
        reader.read().then(read);
      } catch (error) {
        console.error("Error sending message:", error);
        setIsLoading(false);
      }
    }
  };

  const getChats = async (e) => {
    e.preventDefault();
    try {
      if (isLoadingPastChats) return;
      setIsLoadingPastChats(true);
      const jwtToken = await getSessionJwt();
      if (!jwtToken) {
        setIsLoadingPastChats(false);
        return;
      }
      const response = await fetch(`${ENDPOINTS.LAMBDA_ENDPOINT}/get-chats`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      const data = await response.json();
      if (data?.results?.success) {
        setPastChats(data.results.chats);
      }
    } catch (error) {
      console.error("Error getting chats. Message:", error);
    }
    setIsLoadingPastChats(false);
  };

  const getChat = async (e, chatID) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!chatID) {
        setIsLoading(false);
        return;
      }
      const jwtToken = await getSessionJwt();
      if (!jwtToken) {
        setIsLoading(false);
        return;
      }
      setMessages([]);
      const response = await fetch(
        `${ENDPOINTS.LAMBDA_ENDPOINT}/get-chat?chatID=${chatID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      const data = await response.json();
      if (data?.results?.success) {
        setMessages(data.results.chats);
        setChatID(chatID);
      }
    } catch (error) {
      console.error("Error getting this chat. Message:", error);
    }
    setIsLoading(false);
  };

  return (
    <MasterLayout>
      <GptLayout
        headerText={"OPHTHGPT"}
        // functions
        getChat={getChat}
        getChats={getChats}
        sendMessage={sendMessage}
        // general states
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        input={input}
        setInput={setInput}
        responseText={responseText}
        setResponseText={setResponseText}
        messages={messages}
        setMessages={setMessages}
        waitingAfterDone={waitingAfterDone}
        setWaitingAfterDone={setWaitingAfterDone}
        // past chats states
        isLoadingPastChats={isLoadingPastChats}
        pastChats={pastChats}
      />
    </MasterLayout>
  );
}
