


import React from 'react';
import { Typography } from "@mui/material";
import { useMediaQuery } from '@mui/material';

const ImageBannerWithText = ({ header, subHeader, imgLink, imgAltText, height=680 }) => {
    const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div style={{
        height: height, width: "100%", position: "relative"
    }}>
        <img
            src={imgLink}
            alt={imgAltText}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
        />
        <div style={{
            position: "absolute",
            top: 0, // Cover the entire container
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay with 50% opacity
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Typography
                variant="h1"
                sx={{ px: isMobile ? "20px" : "150px", paddingBottom: isMobile ? "5px" : "30px", fontWeight: "bold", color: "white", fontSize: { xs: "20px", md: "36px", lg: "46px" }, textShadow: "2px 3px 5px rgba(0,0,0,0.2);" }}
            >
                {header}
            </Typography>
            <Typography
                variant="h3"
                sx={{ px: isMobile ? "20px" : "150px", color: "white", fontSize: { xs: "16px", md: "25px", lg: "25px" }, textShadow: "2px 3px 5px rgba(0,0,0,0.2);" }}
            >
                {subHeader}
            </Typography>
        </div>
    </div>
  );
};

export default ImageBannerWithText;
