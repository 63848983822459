import Footer from "../components/Footer";
import SiteHeader from "./SiteHeader";

const MasterLayout = (props) => {
  return (
    <>
      <SiteHeader />
      {props.children ? props.children : <h1>Missing props!</h1>}
      <Footer />
    </>
  );
};

export default MasterLayout;
